import React from "react"

const BookImage = ({book}) => {
    // allows this to exist inside a parent link.
    const stopPropagation = function(e){
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }
    
    return (<div className="">
    <img className="" width="80" src={book.frontmatter.image} alt={`Book cover for ${book.frontmatter.title}`} />                            
    <a href={book.frontmatter.link} onClick={stopPropagation}
        className="hover:font-bold hover:border-b-2 flex space-x-1 px-3 py-1" target="_blank" rel="noreferrer">                            
        <img className="w-4 h-4 mt-1" src="/amazon-icon.png" alt="Amazon Link" />                  
        <span className="text-xs" >Buy</span>
    </a>
</div>)}

export default BookImage;