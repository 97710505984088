import * as React from "react"
import { graphql } from "gatsby"
import HeaderPost from "../templates/header-post.component"
import Footer from "../templates/footer.component"
import { Helmet } from "react-helmet"
import BookListFilter from "../templates/book-list-filter"
import Navigation from "../templates/navigation.component"

// markup
const BooksPage = ({
  data,
}) => {
  const books = data.allMdx.nodes;
  const tags = data.tags.group.filter(n => n.totalCount > 1);  
  let navLinks = []
  navLinks.push({ name: "Back to Top", href: "#top", className: "text-sm text-right" });
  navLinks.push({ name: "Popular Tags", className: "text-xl" });
  for (var tag of tags) {
    navLinks.push({ name: `${tag.tag} (${tag.totalCount})`, href: "/tags/" + tag.tag.replace("@", "") + "/" });
  }

  return (
    <main className="">
      <Helmet>
        <title>bitCobblers - Reading List</title>
        <html lang="en" />
      </Helmet>      
      <HeaderPost></HeaderPost>
      <Navigation></Navigation>
      <div className="max-w-screen-xl mx-auto  pb-10">        
          {/* <nav className="bg-gray-100 sticky top-0 drop-shadow shadow-gray-600">Test</nav> */}
          <BookListFilter books={books} className="">          
          </BookListFilter>                
      </div>
      {/* <Footer /> */}

    </main>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: {order: DESC, fields: frontmatter___date}
      filter: { frontmatter: { slug: { glob: "/book/*" } } }
    ) {
      nodes {
        frontmatter {
          slug
          title
          authors
          blogger
          date
          tags
          image
          link
        }
        excerpt(pruneLength: 480)
      }
    }
    
    tags: allMdx 
      (filter: {frontmatter: {published: {eq: true}}}){
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }      
    }

    bloggers: allResumeJson {
      edges {
        node {
          basics {
            id
            email
            name
          }
        }
      }
    }
  
  }
`

export default BooksPage