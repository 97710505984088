import React, { useState } from "react"
import BookImage from "../templates/bookImage.component"
import { Link } from "gatsby";
const BookListFilter = ({books}) => {    
    const [ display, setDisplay ] = useState(books);

    const setSearch = (search) => {
      var lower = search.toLowerCase();
      setDisplay(books.filter(b =>{
        return lower === ""
        || b.frontmatter.title?.toLowerCase().indexOf(lower) > -1 
        || b.frontmatter.subtitle?.toLowerCase().indexOf(lower) > -1
        || b.frontmatter.authors?.filter(a => a.toLowerCase().indexOf(lower) > -1).length > 0;
      }));
    }

    return (        
    <div className="relative  ">
        <nav className="max-w-screen-xl flex md:justify-start mx-auto md:mx-0 px-2 sticky top-10 pb-2 bg-white z-10">        
          <div className="w-full md:flex md:justify-end">
              <input type="search" id="search-dropdown" className="inline-block p-2.5 w-full md:w-80 z-20 text-sm text-gray-900 bg-gray-50 rounded-r-lg rounded-l-lg border-l-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-l-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500" placeholder="Search..." required onChange={e => setSearch(e.target.value)} />
              <button type="submit" className="absolute top-0 md:top-none md:relative right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <svg aria-hidden="true" className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                  <span className="sr-only">Search</span>
              </button>
          </div>        
        </nav>            
        <div id="container"
            className="grid gap-3
            grid-cols-1 
            sm:grid-cols-2
            lg:grid-cols-3">
            {display.map(book => (          
          <Link to={book.frontmatter.slug} key={book.frontmatter.slug} 
               className="col-span-1 rounded-sm border p-2 flex justify-between hover:bg-slate-100"
               title={book.frontmatter.title}
               subtitle={book.frontmatter.subtitle}
               authors={book.frontmatter.authors.join("|")}>
            <div className="w-full p-2">
              <div className="">
                <h2>{book.frontmatter.title}</h2>
                <div>by
                  {book.frontmatter.authors.map(writer => (
                    <span key={writer} className="px-2 py-1 first:border-none border-l-2">
                      <span>{writer}</span>
                    </span>))}</div>
              </div>
              <div></div>
            </div>
            <BookImage className="w-22 w-min-22" book={book}>              
            </BookImage>                          
          </Link>
          ))}
        </div>
    </div>
)}

export default BookListFilter